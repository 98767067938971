// List of Base URL for Microservices
    // const user_url = 'http://localhost:5000/user/';
    // const contractor_url = 'http://localhost:5000/contractor';
    // const content_url = 'http://localhost:5000/content';
    // const vendor_url = 'http://localhost:5000/vendor';
    // const category_url = 'http://localhost:5000/category';
    // const listing_url = 'http://localhost:5000/listing';
    // const FeedBack_url = 'http://localhost:5000/feedback'
    // const About_url = 'http://localhost:5000/about'
    // const Slug_url = 'http://localhost:5000/slug'
    // const Message_url= 'http://localhost:5000/messages'
    // const carausel_url= 'http://localhost:5000/carausel'
    // const social_url= 'http://localhost:5000/auth'
    // const media_url= 'http://localhost:5000/media'
    // const db_url= 'http://localhost:5000/db'
    // const post_url= 'http://localhost:5000/post'
    
//Live url
const user_url = 'https://sourcify-back-o8lc8.ondigitalocean.app/user/';
const contractor_url = 'https://sourcify-back-o8lc8.ondigitalocean.app/contractor';
const content_url = 'https://sourcify-back-o8lc8.ondigitalocean.app/content';
const vendor_url = 'https://sourcify-back-o8lc8.ondigitalocean.app/vendor';
const category_url = 'https://sourcify-back-o8lc8.ondigitalocean.app/category';
const listing_url = 'https://sourcify-back-o8lc8.ondigitalocean.app/listing';
const FeedBack_url = 'https://sourcify-back-o8lc8.ondigitalocean.app/feedback'
const About_url = 'https://sourcify-back-o8lc8.ondigitalocean.app/about'
const Slug_url = 'https://sourcify-back-o8lc8.ondigitalocean.app/slug'
const Message_url= 'https://sourcify-back-o8lc8.ondigitalocean.app/messages' 
const carausel_url= 'https://sourcify-back-o8lc8.ondigitalocean.app/carausel'
const social_url= 'https://sourcify-back-o8lc8.ondigitalocean.app/auth'
const media_url= 'https://sourcify-back-o8lc8.ondigitalocean.app/media'
const db_url= 'https://sourcify-back-o8lc8.ondigitalocean.app/db'
const post_url= 'https://sourcify-back-o8lc8.ondigitalocean.app/post'
export { user_url,social_url, Slug_url,contractor_url,content_url,vendor_url,category_url,listing_url,FeedBack_url,About_url,Message_url , carausel_url,media_url,db_url,post_url};