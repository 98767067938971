import React from 'react'

const TopBar = () => {
  return (
    <div className='h-full bg-[#FF5757]'>
      <marquee className="text-white align-middle text-[16px] italic " data-translate="hi" >
        Sourcify is a free platform for collaboration and we don't charge any Fees / Commission
      </marquee>
    </div>
  )
}

export default TopBar